@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --green: #6FD65E;
    --green-light: #D5FFCE;
    --pink: #EB5BEE;
    --pink-light: #feccff;
    --pink-lighter: #FFE5E5;
    --blue: #1472FF;
    --blue-light: #B6D3FF;
    --gray-darker: #808080;
    --gray-dark: #A7A7A7;
    --gray: #E0E0E0;
    --gray-light: #EDEDED;
    --gray-lighter: #F3F3F3;
    --gray-lighest: #F8F9FB;
    --black: #444444;
}   

a {
    color: #1472FF;
    color: var(--blue);
    text-decoration: none;
}

button {
    outline: none;
    font-size: 16px;
    border: none;
    background-color: #E0E0E0;
    background-color: var(--gray);
    border-radius: 2px;
    padding: 15px;
    letter-spacing: 0.5px;
    font-family: 'Poppins', sans-serif;
}
  .swiperMainContainer {
    /* flex: 1;
    height: 100%;
    background-color: var(--gray-lighest); */
    background-color: var(--gray-lighest);
    height: 100%;
  }
  
  .swiper-container {
    /*width: 100vw;*/
    height: 100%;
    height: 100%;
  }
  
  .swiper-slide {
      /* display: flex;
      align-items: center;
      flex-direction: column; */
  }
  
