  .swiperMainContainer {
    /* flex: 1;
    height: 100%;
    background-color: var(--gray-lighest); */
    background-color: var(--gray-lighest);
    height: 100%;
  }
  
  .swiper-container {
    /*width: 100vw;*/
    height: 100%;
    height: 100%;
  }
  
  .swiper-slide {
      /* display: flex;
      align-items: center;
      flex-direction: column; */
  }
  